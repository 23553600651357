import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@material-ui/core';
import { TextFieldTemplate } from '../../input/textfield-template';

export const LoginInfo = ({ user, handleInput, helperText }) => {

    const RoleOptions = [
        {
            label: 'User',
            value: 0
        }, {
            label: 'Admin',
            value: 1
        }, {
            label: 'No role',
            value: 2
        }
    ]

    return (
        <Box>
            {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
            <Typography
                variant="h6"
                color="primary"
            >Name</Typography>
            <Box py={5}>
                <TextFieldTemplate
                    readonly
                    size='small'
                    label='Username'
                    defaultValue={user?.UserName}
                />
            </Box>
            <Box py={5}>
                <TextFieldTemplate
                    size='small'
                    label='Display Name'
                    defaultValue={user?.DisplayName}
                    onChange={e => {
                        handleInput('DisplayName', e.target.value)
                    }}
                />
            </Box>
            <Box py={5}>
                <TextField
                    color='primary'
                    variant='outlined'
                    size='small'
                    select
                    label='Role'
                    value={user?.Role}
                    style={{width: 200}}
                    onChange={e => {
                        handleInput('Role', e.target.value)
                    }}
                >
                    {RoleOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                </TextField>
            </Box>
            <Typography
                variant="h6"
                color="primary"
            >Contact Info</Typography>
            <Box py={5}>
                <TextFieldTemplate
                    size='small'
                    label='Email'
                    readonly
                    value={user?.Email}
                />
            </Box>
        </Box>
    )
}