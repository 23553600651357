import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { TextFieldTemplate } from '../../input/textfield-template';

export const ProfileQuestions = ({ account, handleSetAccount }) => {
    const OtherForm = [
        { key: "CaptivatedBy", label: "Captivated by" },
        { key: "NeedToLive", label: "Can't live without" },
        { key: "GratefulFor", label: "Deeply grateful for" },
    ];

    return (
        <Box>
            <Typography
                variant="h6"
                color="primary"
            >Profile Questions</Typography>
            {OtherForm.map(item => (
                <Box py={5} key={item.key}>
                    <TextFieldTemplate
                        size="small"
                        label={item.label}
                        multiline
                        rows={7}
                        value={(account != null && item.key in account) ? (account[item.key] || ""): ""}
                        helperText={item.helperText}
                        onChange={e => handleSetAccount(item.key, e.target.value)}
                        inputProps={{
                            maxLength: 175
                        }}
                    />
                </Box>
            ))}
        </Box>
    )
}