import React from 'react';
import { Box, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import moment from 'moment';
import { getImage } from '../../../lib/utils';
import noimage from '../../../images/noimage.png';

const useStyle = makeStyles(() => ({
    table: {
        border: '1px solid #d4d4d4',
        '& .MuiTableCell-head': {
            fontWeight: 'bold'
        }
    },
    row: {
        verticalAlign: 'top',
        '&:hover': {
            '& .MuiBox-root': {
                display: 'block'
            }
        }
    },
    image: {
        width: '60px',
        height: '60px',
        objectFit: 'cover'
    },
    link: {
        textDecoration: 'none'
    },
}));

export const RentalHistory = ({ rentals, UserID }) => {
    const { table, row, link, image } = useStyle();

    const displayDate = (date) => {
        return moment(date).format('Do MMMM, YYYY');
    }

    const displayExpireDate = (date) => {
        return moment(date).add(21, 'days').format('Do MMMM, YYYY');
    }

    const displayPackageID = (rental) => {
        if (rental.RentType === 1) {
            if (parseInt(rental.PackageName) === 1) {
                return 'Quickie';
            } else if (parseInt(rental.PackageName) === 2) {
                return 'Monthly';
            } else if (parseInt(rental.PackageName) === 3) {
                return 'Binge';
            }
        }

        return rental.PackageName;
    }

    const rentalTypeText = (type) => {
        if (type === 1) {
          return 'subscription';
        } else if (type === 2) {
          return 'credit';
        } else if (type === 3) {
          return 'lacarte';
        }
    }

    return (
        <Box>
            <Grid container justify='space-between'>
                <Typography variant='h6'>Latest 5 videos rental history</Typography>
                <Link to={`/rental-histories/${UserID}`}>View all</Link>
            </Grid>
            <TableContainer className={table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Package Id</TableCell>
                            <TableCell>Video Title</TableCell>
                            <TableCell>Video Thumb</TableCell>
                            <TableCell>MLNP Star name</TableCell>
                            <TableCell>Rental type</TableCell>
                            <TableCell>Rental date</TableCell>
                            <TableCell>Expire date</TableCell>
                        </TableRow>
                    </TableHead>
                    {rentals?.Items?.length > 0 &&
                        <TableBody>
                            {rentals?.Items?.map(rental => (
                                <TableRow className={row} key={rental.VideoID}>
                                    <TableCell>{displayPackageID(rental)}</TableCell>
                                    <TableCell>
                                        <Link to={`${process.env.GATSBY_USER_SITE}video/${rental.VideoID}`} className={link}>{rental.VideoTitle}</Link>
                                    </TableCell>
                                    <TableCell><img src={rental?.Stills[0]?.key ? getImage(rental?.Stills[0]) : noimage} className={image} /></TableCell>
                                    <TableCell>
                                        <Link to={`${process.env.GATSBY_USER_SITE}mlnpstar/${rental.StarUserName}`} className={link}>{rental.StarUserName}</Link>
                                    </TableCell>
                                    <TableCell>{rentalTypeText(rental.RentType)}</TableCell>
                                    <TableCell>{displayDate(rental.CreatedAt)}</TableCell>
                                    <TableCell>{displayExpireDate(rental.CreatedAt)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>}
                </Table>
            </TableContainer>
        </Box>
    )
}