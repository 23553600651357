import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { GetEarningAdmin } from '../../../lib/graphql-command/user-command';
import { API, graphqlOperation } from 'aws-amplify';

export const OtherUserInfo = ({ user }) => {
  const [earning, setEarning] = useState({});
  let timeDiff = null;

    useEffect(() => {
      API.graphql(graphqlOperation(GetEarningAdmin, { UserID: user.UserID }))
      .then(result => setEarning(result.data?.getEarningAdmin))
      .catch(error => console.log(error));
  }, []);

  const getTimeDiff = (date) => {
    if (!date) return 'user has not logged in';
    return timeDiff = moment(date).fromNow();
  }

  console.log(earning)

  const OtherInfos = [
    {
      key: 0,
      value: moment(user.CreatedAt).format('DD MMM, YYYY'),
      label: 'Membership Registration Date'
    }, {
      key: 1,
      value: getTimeDiff(user.LastLogin),
      label: 'Last sign in date'
    }, {
      key: 2,
      value: earning?.VideoSubmit?.First ? moment(earning.VideoSubmit?.First).format('DD MMM, YYYY') : 'No video submited',
      label: 'First video submission date'
    }, {
      key: 3,
      value: earning?.VideoSubmit?.Last ? moment(earning.VideoSubmit?.Last).format('DD MMM, YYYY') : 'No video submited',
      label: 'Last video submission date'
    }, {
      key: 4,
      value: earning?.VideoSubmit?.Total,
      label: 'Number of video submissions'
    }, {
      key: 5,
      value: earning?.Earning?.RentNumber,
      label: 'Number of rentals'
    }, {
      key: 6,
      value: `$${earning?.Earning?.MonthlyEarning?.toFixed(2) || 0}`,
      label: 'Earnings for current payout'
    }, {
      key: 7,
      value: `$${earning?.Earning?.LifetimeEarning?.toFixed(2) || 0}`,
      label: 'Total lifetime earnings'
    }
  ]

  return (
    <Box>
      {OtherInfos.map(info => (
        <Box py={5} display='flex' key={info.key}>
          <Box mr={3} style={{width: 300}}>
            <Typography><strong>{info.label}:</strong></Typography>
          </Box>
          <Typography>{info.value}</Typography>
        </Box>
      ))}

    </Box>
  )
}