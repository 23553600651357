import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { TextFieldTemplate } from '../../input/textfield-template';

const SocialNetwork = [
    {
        key: "Facebook",
        icon: <FacebookIcon color="primary"/>
    },
    {
        key: "Twitter",
        icon: <TwitterIcon color="primary"/>
    },
    {
        key: "Instagram",
        icon: <InstagramIcon color="primary"/>
    },
];

export const SocialMedia = ({ account, setAccount }) => {
    const handleInput = (key, value) => {
        setAccount(account => ({
            ...account,
            SocialLink: {
                ...account.SocialLink,
                [key]: value
            }
        }))
    };

    return (
        <Box>
            <Typography
                variant="h6"
                color="primary"
            >Social Media Links</Typography>
            {SocialNetwork.map(item => (
                <Box py={5} key={item.key}>
                    <TextFieldTemplate
                        size="small"
                        label={item.key}
                        value={(account.SocialLink != null && item.key in account.SocialLink) ? (account.SocialLink[item.key] || ""): ""}
                        startadornment={
                            <Box display="flex" alignItems="center">
                                {item.icon}
                                <Box pl={5}>https://</Box>
                            </Box>
                        }
                        onChange={e => handleInput(item.key, e.target.value)}
                    />
                </Box>
            ))}
        </Box>
    )
}