import _ from 'lodash';

// Check empty
export const isNotNullOrEmpty = value => !!value;

// Check valid format date
export const isValidDate = label => value => {
    if (value === 'Invalid date') {
        throw new Error(`Please enter ${label} in the requested format - MM/DD/YYYY`);
    }
    return true;
}

export const isValidMonth = label => value => {
    if (value === 'Invalid date') {
        throw new Error(`Please enter ${label} in the requested format - MM/YYYY`);
    }
    return true;
}

// Check valid format email
export const isValidEmail = value => {
    if (value && !/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        throw new Error(`Invalid email format`)
    }
    return true;
}

// Check length
export const CheckMinLength = min => value => {
    if (value.length < min) {
        throw new Error(`Minimum ${min} characters`)
    }
    return true;
}

export const CheckMaxLength = max => value => {
    if (value.length > max) {
        throw new Error(`Maximum ${max} characters. Please shorten the address.`)
    }
    return true;
}

// Check valid age
export const limitAge = date => {
    if ((Date.now() - new Date(date))/(31557600000) < 18) {
        throw new Error('Age should be 18 years old or older.')
    }
    return true;
}

// Check required field
export const isRequired = label => value => {
    if (!isNotNullOrEmpty(value)) {
        throw new Error(`${label} is required`);
    }
    return true;
}

// Check required number field
export const isNumberOnly = value => {
    if (value && !/^\d+$/.test(value)) {
        throw new Error(`Please enter number only`)
    }
    return true;
}

// Check password
export const validPassword = () => value => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_\-\+={};:'",<.>/?`~]{8,}$/;
    if (!pattern.test(value)) {
        throw new Error(`At least 1 lowercase, 1 uppercase letter, 1 number and 8 characters`)
    }
    return true;
}

export const passwordIsMatch = password => retype_password => {
    if (password !== retype_password) {
        throw new Error(`Passwords don't match`)
    }
    return true;
}

// Check valid form
const validateField = (data, config) => {
    try {
        for (let i = 0; i < config.validateFuncs.length; i++) {
            config.validateFuncs[i](data[config.key])
        }
        return null;
    } catch (e) {
        return e.message
    }
}

export const validateData = (data, mandatoryFields) => {
    const errors = {};
    for (let i in mandatoryFields) {
        const result = validateField(data, mandatoryFields[i]);
        if (result) {
            errors[mandatoryFields[i].key] = result;
        }
    }
    return errors;
}