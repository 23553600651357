import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import API, { graphqlOperation } from '@aws-amplify/api';
import { GetSubAdmin } from '../../../lib/graphql-command/user-command';
import { getSubscriptionPackage } from '../../../lib/utils';
import moment from 'moment';
import { ButtonTemplate } from '../../button/button-template';
import { CancelSubscriptionDialog } from '../../subscription-history/cancel-subscription-dialog';
import MessageDialog from '../../dialog/message-dialog';
import { AddCreditDialog } from './add-credit-dialog';

export const SubInfo = ({ user }) => {
    const [sub, setSub] = useState({});
    const [disabled, setDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [openCancelDialog, setOpenCancelDialog] = useState(false)
    const [openAddCredit, setOpenAddCredit] = useState(false )

    useEffect(() => {
        getSub();
    }, []);

    const getSub = () => {
        API.graphql(graphqlOperation(GetSubAdmin, { UserID: user.UserID }))
        .then(result => setSub(result.data?.getSubAdmin))
        .catch(error => console.log(error));
    }

    const StartDate = () => {
        const MonthDiff = moment().diff(moment(sub?.SubPlan?.StartDate), 'month');
        const date = moment(sub?.SubPlan?.StartDate).add(MonthDiff, "month").format('DD MMM, YYYY')
        return sub?.SubPlan?.StartDate ? date : 'None'
    }

    const NextBillingDate = () => {
        const MonthDiff = moment().diff(moment(sub?.SubPlan?.StartDate), 'month');
        const date = moment(sub?.SubPlan?.StartDate).add(MonthDiff + 1, "month").format('DD MMM, YYYY')
        // if(sub?.SubPlan?.ParentPackage > sub?.SubPlan?.Package) {
        //     return `Next billing date and plan: ${getSubscriptionPackage(subscription?.Package)} billed on ${date}`
        // }
        return sub?.SubPlan?.StartDate ? date : 'None'
    }

    const SubInfos = [
        {
          key: 0,
          value: sub?.Point?.SubPoint || 0,
          label: 'Subscription Credits'
        }, {
          key: 1,
          value: sub?.Point?.BundlePoint || 0,
          label: 'Bundle Credits'
        }, {
          key: 2,
          value: getSubscriptionPackage(sub?.SubPlan?.Package) ,
          label: 'Subscription Plan'
        }, {
          key: 3,
          value: sub?.SubPlan?.Status === 1 ? 'Active' : sub?.SubPlan?.Status === 0 ? 'Canceled' : 'None',
          label: 'Subscription Status'
        }, {
          key: 4,
          value: sub?.SubPlan?.Status !== 1 ? 'None' : StartDate(),
          label: 'Membership started'
        }, {
          key: 5,
          value: sub?.SubPlan?.Status !== 1 ? 'None' : NextBillingDate(),
          label: 'Next Billing Date'
        }
    ]

    const handleCancel = () => {
        setOpenCancelDialog(true)
    }

    return (
        <Box p={10} style={{border: '1px solid', width: '50%'}}>
            {/* <pre>{JSON.stringify(sub, null, 2)}</pre> */}
            {SubInfos.map(info => (
                <Box py={5} display='flex' key={info.key}>
                    <Box mr={3} style={{width: 300}}>
                        <Typography><strong>{info.label}:</strong></Typography>
                    </Box>
                    <Typography>{info.value}</Typography>
                    {info.key === 1 && <ButtonTemplate
                        borderradius='5px'
                        variant='contained'
                        color='primary'
                        size='small'
                        margin='-5px 0 0 20px'
                        onClick={() => setOpenAddCredit(true)}
                    >Add</ButtonTemplate>}
                </Box>
            ))}

            {sub?.SubPlan?.Status === 1 && <Box py={5} display='flex'>
                <Box mr={3} style={{width: 300}}>
                    <Typography><strong>Cancel Membership:</strong></Typography>
                </Box>
                <ButtonTemplate
                    disabled={disabled}
                    borderradius='5px'
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={handleCancel}
                >Cancel Membership</ButtonTemplate>
            </Box>}
            <CancelSubscriptionDialog {...{open: openCancelDialog, setOpen: setOpenCancelDialog, subscription: sub?.SubPlan, getListSub: getSub, setMessage}} />
            <MessageDialog {...{message, setMessage}} />
            <AddCreditDialog {...{open: openAddCredit, setOpen: setOpenAddCredit, setSub, user}} />
        </Box>
    )
}