import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const AutocompleteTemplate = (props) => (
    <Autocomplete
        {...props}
        fullWidth
        renderInput={(params) => (
            <TextField
                {...params}
                variant='outlined'
                size='small'
                label={props.label}
                helperText={props.helpertext}
                error={props.helpertext ? true : false}
            />
        )}
    />
)