import React, { useState } from 'react';
import { Box, FormHelperText, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Auth, Storage } from "aws-amplify";
import defaultAvatar from '../../../images/MLNP_Heart_Symbol.png';
import { RenameFile } from '../../../lib/rename';
import { ButtonTemplate } from '../../button/button-template';
import { ProgressBar } from '../../progress/progress-bar';

const useStyle = makeStyles(theme => ({
    input: {
        display: "none"
    },
    image: {
        width: "150px"
    },
    wrap: {
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'rgba(265, 265, 265, 0.5)',
        color: theme.palette.grey[800],
        "&:hover": {
            backgroundColor: 'rgba(265, 265, 265, 0.2)',
            color: theme.palette.primary.main
        }
    },
}));

const bucket = process.env.GATSBY_BUCKET_IMAGE;

export const UploadAvatar = ({ account, handleSetAccount }) => {
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState();
    const { input, image, wrap, closeButton } = useStyle();

    const handleUpload = (file, maxSize, maxSizeText) => {
        setError();
        setProgress(null);

        if (file.size > maxSize) {
            setError(`Invalid file. Please upload jpg, jpeg, png or gif files with maximum size ${maxSizeText}.`);
            return false;
        }

        handleSetAccount("Avatar", null);

        Storage.configure({ bucket: bucket });

        Auth.currentCredentials()
        .then(data => {
            const key = `public/images/${data.identityId}/${RenameFile(file.name)}`;

            Storage.put(key, file, { contentType: file.type, customPrefix: { public: "" },
                progressCallback(progress) {
                    setProgress((100 * progress.loaded) / progress.total)
                },
            })
            .then(data => {
                handleSetAccount("Avatar", {
                    region: "us-west-1",
                    bucket: bucket,
                    key: data.key,
                })
            })
            .catch(err => console.log(err))
        });
    }

    const handleRemove = () => {
        setProgress(null);
        handleSetAccount("Avatar", null);
    }

    return (
        <Box py={5}>
            <Grid container direction="row" alignItems="stretch">
                <Grid item>
                    <input
                        className={input}
                        id="avatar"
                        multiple
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        onChange={e => handleUpload(e.target.files[0], 5000000, '5Mb')}
                    />
                    <label htmlFor="avatar">
                        <ButtonTemplate
                            color="primary"
                            variant="contained"
                            borderradius="5px"
                            size="small"
                            component="span"
                            changetextcolor="#fff"
                        >Upload avatar</ButtonTemplate>
                    </label>
                </Grid>
                <Grid item xs={6} sm={8}>
                    {error ?
                        <Box pl={5}>
                            <FormHelperText error>{error}</FormHelperText>
                        </Box>
                    :
                        <Box>
                            <Box px={10} width="50%">
                                {account?.Avatar?.key ?
                                    <Box className={wrap}>
                                        <img src={`${process.env.GATSBY_CLOUDFRONT_DOMAIN}${account?.Avatar?.key}`} alt="avatar" className={image} />
                                        <IconButton onClick={() => handleRemove()} size="small" className={closeButton}>
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </Box>
                                :
                                    <Box className={wrap}>
                                        <img src={defaultAvatar} alt="avatar" className={image} />
                                    </Box>
                                }
                            </Box>
                            <Box width="80%" px={5}>
                                {!account?.Avatar?.key && progress && <ProgressBar value={progress} />}
                            </Box>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}