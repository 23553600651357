import { Box, Typography, Grid } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { ListRentalHistoryAdmin } from '../../lib/graphql-command/command';
import React, { useEffect, useState } from 'react';
import { ResendConfirmation } from '../../components/users/resend-confirmation';
import { GetUserAdmin, UpdateUserAdmin } from '../../lib/graphql-command/user-command';
import { OtherUserInfo } from '../../components/users/user-profile/other-info';
import { LoginInfo } from '../../components/users/user-profile/login-info';
import { SubInfo } from '../../components/users/user-profile/sub-info';
import { RentalHistory } from '../../components/users/user-profile/rental-history';
import { PronounsComp } from '../../components/users/edit-user/pronouns';
import { ProfileQuestions } from '../../components/users/edit-user/profile-questions';
import { UploadAvatar } from '../../components/users/edit-user/upload-avatar';
import { PrivateAccount } from '../../components/users/edit-user/private-account';
import { SocialMedia } from '../../components/users/edit-user/social-media';
import MessageDialog from '../../components/dialog/message-dialog';
import { ButtonTemplate } from '../../components/button/button-template';
import { navigate } from 'gatsby';
import { NewPassword } from '../../components/users/edit-user/new-password';
import { globalHistory } from '@reach/router';

const EditUser = ({ UserID }) => {
  const [rentals, setRentals] = useState();
  const [user, setUser] = useState()
  const [helperText, setHelperText] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    API.graphql(graphqlOperation(GetUserAdmin, { UserID }))
      .then(result => setUser(result.data.getUserAdmin))
      .catch(err => console.log(err))

    getRentalHistories();
  }, [])

  const getRentalHistories = () => {
    API.graphql(graphqlOperation(ListRentalHistoryAdmin, {
      NumberItem: 5,
      Page: 1,
      FilterUserID: UserID
    }))
      .then(result => {
        setRentals(result.data?.getRentalHistoryListAdmin);
        console.log('rentals', rentals);
      })
      .catch(err => console.log('rentals-err', err))
  }

  const handleInput = (item, data) => {
    setUser(input => ({
      ...input,
      [item]: data,
    }))
    setHelperText(helperText => ({
      ...helperText,
      [item]: "",
    }))
  }

  const PasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_\-\+={};:'",<.>/?`~]{8,}$/
  const handleSubmit = () => {
    if(user.Password && !PasswordPattern.test(user.Password)) {
      setHelperText({Password: 'At least 1 lowercase, 1 uppercase letter, 1 number and 8 characters.'})
      return
    }
    setDisabled(true)
    let isValid = true
    // for (let i in mandatoryFields) {
    //     if (!checkHelperText(mandatoryFields[i])) {
    //         isValid = false
    //     }
    // }
    if (isValid) {
        API.graphql(graphqlOperation(UpdateUserAdmin, user))
        .then(() => {
            setDisabled(false)
            setMessage('Update successfully')
        })
        .catch(err => {
            console.log(err)
        })
    } else {
        setDisabled(false)
    }
}

  console.log(user)

  return (
    <Box>
      <Grid container>
        <Typography variant='h4'>{globalHistory?.location?.pathname === '/profile' ? 'Profile' : 'Edit User'}</Typography>
        <ButtonTemplate
            borderradius='50px'
            variant='outlined'
            color='primary'
            size='small'
            margin='0 0 5px 10px'
            onClick={() => navigate('/new-user')}
        >Add New</ButtonTemplate>
      </Grid>
      {user && <Box py={10}>
        {user.Status === 0 && <Box py={5}>
          <ResendConfirmation UserName={user.UserName} UserID={UserID} VerifyLink={user.VerifyLink} />
        </Box>}
        <Box py={10} style={{width: '50%'}}>
          <LoginInfo {...{ user, handleInput, helperText }} />
        </Box>
        <Box py={10} style={{width: '50%'}}>
          <NewPassword {...{ user, handleInput, helperText }}/>
        </Box>
        <Box py={10} style={{width: '50%'}}>
          <ProfileQuestions {...{account: user, handleSetAccount: handleInput}} />
        </Box>
        <Box py={10} style={{width: '50%'}}>
          <PronounsComp {...{account: user, setAccount: setUser}}/>
        </Box>
        <Box py={5} style={{width: '50%'}}>
          <SocialMedia {...{ account: user, setAccount: setUser }} />
        </Box>
        <Box py={5}>
          <UploadAvatar {...{ account: user, handleSetAccount: handleInput }} />
        </Box>
        <Box py={5}>
            <PrivateAccount {...{ account: user, handleSetAccount: handleInput }} />
        </Box>
        <Box py={10}>
          <OtherUserInfo {...{ user }} />
        </Box>
        <Box py={10}>
          <SubInfo {...{ user }} />
        </Box>
        {rentals?.Items?.length > 0 && <Box py={10}>
          <RentalHistory {...{ rentals, UserID }} />
        </Box>}
      </Box>}

      <ButtonTemplate
          disabled={disabled}
          borderradius='50px'
          variant='outlined'
          color='primary'
          onClick={handleSubmit}
      >
          Save
      </ButtonTemplate>
      <MessageDialog {...{message, setMessage}} />
    </Box>
  )
}

export default EditUser;