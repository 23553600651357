import React, { useState } from 'react'
import { Box, Grid, Button, TextField, Typography } from '@material-ui/core'
import { ButtonTemplate } from '../../button/button-template'

const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export const NewPassword = ({user, handleInput, helperText}) => {
    const [cancel, setCancel] = useState(true)

    const handleCancel = () => {
        setCancel(true);
        handleInput('Password', '');
    }

    const handleGeneratePassword = () => {
        setCancel(false)
        let random = '';
        for (let i = 0; i < 10; i++) {
          random += charset.charAt(Math.floor(Math.random() * charset.length));
          if (i === 3) random += '1';
          if (i === 5) random += 'A';
          if (i === 8) random += 'a';
        };
        return random;
    };

    return (
        <Box>
            <Typography
                variant="h6"
                color="primary"
            >Account Management</Typography>
            <Box py={5}>
                <ButtonTemplate
                    borderradius='5px'
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => handleInput('Password', handleGeneratePassword())}
                >
                    Set New Password
                </ButtonTemplate>
            </Box>
            <Box display={cancel ? 'none' : 'inline-block'}>
                <TextField
                    label='Password'
                    value={user?.Password}
                    size="small"
                    variant="outlined"
                    helperText={helperText.Password}
                    error={!!helperText.Password}
                    onChange={(event) => handleInput('Password', event.target.value)}
                />
                <ButtonTemplate
                    padding='5px 9px'
                    borderradius='5px'
                    margin='0 0 0 5px'
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={handleCancel}
                >
                    Cancel
                </ButtonTemplate>
            </Box>
        </Box>
    )
}