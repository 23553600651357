import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonTemplate } from '../button/button-template';
import { API, graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { GetUserAdmin } from '../../lib/graphql-command/user-command';

export const ResendConfirmation = ({ UserName, UserID }) => {
    const [disabled, setDisabled] = useState(false);
    const [messageResult, setMessageResult] = useState('');
    const [user, setUser] = useState()

    const handleResendConfirmation = () => {
        setDisabled(true);
        resendConfirmation();
        setDisabled(false);
	}

    async function resendConfirmation() {
        try {
            const a = await Auth.resendSignUp(UserName);
            console.log(a)
            setMessageResult('Email sent to user');
            API.graphql(graphqlOperation(GetUserAdmin, {UserID}))
            .then(result => {setUser(result.data.getUserAdmin);})
            .catch(err => console.log(err))
        } catch (err) {
            setMessageResult('Resend failed');
        }
    };

    return (
        <Box>
            <ButtonTemplate variant='contained'
										color='primary'
										borderradius='50px'
										onClick={handleResendConfirmation}
										disabled={disabled}
									>Resend Confirmation</ButtonTemplate>

            <Box p={5}>{messageResult}</Box>
        </Box>
    )
}