import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import _ from 'lodash';
import { isRequired, validateData } from '../../../config/form-config/config';
import { AutocompleteTemplate } from '../../input/autocomplete-template';
import { TextFieldTemplate } from '../../input/textfield-template';
import { ButtonTemplate } from '../../button/button-template';

const defaultPronounsList = ["She/Her", "He/Him", "They/Them", "Ze/Hir", "Ze/Zir"];
const defaultPronounsItem = { Gender: "", Name: "", Key: null };
const emptyErrors = { Pronoun: "", Name: "" };

const useStyle = makeStyles(theme => ({
    tableHeaderStyle: {
        backgroundColor: theme.palette.primary.main,
    },
    tableHeaderTextStyle: {
        color: theme.palette.primary.contrastText
    },
    tableRowTextStyle: {
        fontSize: "0.9em",
        wordWrap: "break-word",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 100
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: 250
        }
    }
}));

export const PronounsComp = ({ account, setAccount }) => {
    const { tableHeaderStyle, tableHeaderTextStyle, tableRowTextStyle } = useStyle();
    const [pronounsList, setPronounsList] = useState([]);
    const [pronounsItem, setPronounsItem] = useState(defaultPronounsItem);
    const [gender, setGender] = useState(null);
    const [errors, setErrors] = useState(emptyErrors);
    const [edit, setEdit] = useState(null);

    const validateFields = [
        { key: "Gender", validateFuncs: [isRequired("Pronoun")] },
        { key: "Name", validateFuncs: [] }
    ]

    useEffect(() => {
        setPronounsList(account?.Pronouns?? []);
    }, [account.Pronouns]);

    const processData = (data) => {
        const newData = data.map((item, index) => ({
            Gender: item,
            Key: index + 1
        }))
        return newData;
    }

    const handleAddPronouns = () => {
        setErrors(emptyErrors);
        const errors = validateData(pronounsItem, validateFields);

        if (!_.isEmpty(errors)) {
            setErrors(errors);
        } else {
            if (!edit) {
                const newPronounsItem = pronounsItem;
                newPronounsItem.Key = pronounsList.length + 1;
                setPronounsList(pronounsList => ([
                    ...pronounsList,
                    newPronounsItem
                ]));
                setAccount(account => ({
                    ...account,
                    Pronouns: [
                        ...account.Pronouns,
                        pronounsItem
                    ]
                }));
            } else {
                const newPronounsList = pronounsList.map(item => {
                    if (item.Key === edit) {
                        return pronounsItem;
                    }
                    return ({ ...item });
                });
                setPronounsList(newPronounsList);
                setAccount(account => ({
                    ...account,
                    Pronouns: newPronounsList
                }));
            }
            setPronounsItem(defaultPronounsItem);
            setGender(null);
            setEdit(null);
        }
    }

    const handleSetPronouns = (item, value) => {
        setPronounsItem(pronounsItem => ({
            ...pronounsItem,
            [item]: value
        }))
    }

    const handleEdit = (item) => {
        setErrors(emptyErrors);
        setPronounsItem(item);
        setGender(processData(defaultPronounsList).filter(pronoun => pronoun.Key === item.Gender)[0]);
        setEdit(item.Key);
    }

    const handleDelete = (item) => {
        const newPronounsList =  pronounsList.filter(pronoun => pronoun !== item);
        setPronounsList(newPronounsList);
        setAccount(account => ({
            ...account,
            Pronouns: newPronounsList
        }));
    }

    const PronounInput = () => (
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={5}>
            <Grid item xs={6} sm={5}>
                <AutocompleteTemplate
                    label="Pronouns"
                    options={processData(defaultPronounsList)}
                    getOptionLabel={option => option?.Gender}
                    value={gender}
                    helpertext={errors.Gender}
                    onChange={(e, value) => {
                        setGender(value);
                        handleSetPronouns("Gender", value?.Key);
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={5}>
                <TextFieldTemplate
                    size="small"
                    label="Name"
                    value={pronounsItem?.Name}
                    helperText={errors.Name}
                    onChange={e => handleSetPronouns("Name", e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Grid container justify="center">
                    <ButtonTemplate
                        size="small"
                        color="primary"
                        borderradius="5px"
                        variant="contained"
                        changetextcolor="#fff"
                        padding="3px 20px"
                        disabled={!!edit ? false : pronounsList.length >= 4}
                        notloading="true"
                        onClick={handleAddPronouns}
                    >Save</ButtonTemplate>
                </Grid>
            </Grid>
        </Grid>
    )

    const Actions = (row) => {
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item>
                    <ButtonTemplate
                        variant="outlined"
                        size="small"
                        onClick={() => handleEdit(row)}
                    >
                        <Typography className={tableRowTextStyle}>Edit</Typography>
                    </ButtonTemplate>
                </Grid>
                <Grid item>
                    <ButtonTemplate
                        variant="outlined"
                        size="small"
                        onClick={() => handleDelete(row)}
                    >
                        <Typography className={tableRowTextStyle}>Delete</Typography>
                    </ButtonTemplate>
                </Grid>
            </Grid>
        )
    }

    const Row = ({ row }) => (
        <TableRow>
            <TableCell className={tableRowTextStyle} align="center">{processData(defaultPronounsList).filter(pronoun => pronoun.Key === row.Gender)[0].Gender}</TableCell>
            <TableCell className={tableRowTextStyle} align="center">{row.Name}</TableCell>
            <TableCell align="center">{Actions(row)}</TableCell>
        </TableRow>
    )

    const PronounTable = () => (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size='small'>
                    <TableHead>
                        <TableRow className={tableHeaderStyle}>
                            <TableCell align="center" className={tableHeaderTextStyle}>Pronoun</TableCell>
                            <TableCell align="center" className={tableHeaderTextStyle}>Name</TableCell>
                            <TableCell align="center" className={tableHeaderTextStyle}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pronounsList.map((item, index) => (
                            <Row key={index} row={item} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )

    return (
        <Box py={5}>
            <Typography
                variant="h6"
                color="primary"
            >Pronouns</Typography>
            {PronounInput()}
            <Box pt={5}>
                {pronounsList.length > 0 &&
                    <PronounTable />
                }
            </Box>
        </Box>
    )
}