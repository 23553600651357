import React, { useRef, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation } from 'aws-amplify';
import { ButtonTemplate } from '../../button/button-template';
import { AddBundleCreditAdmin } from '../../../lib/graphql-command/user-command';

const useStyle = makeStyles(theme => ({
    dialogTitle: {
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.grey[500]
    },
}))


export const AddCreditDialog = ({open, setOpen, setSub, user}) => {
    const [disabled, setDisabled] = useState(false);
    const inputRef = useRef();

    const handleAdd = () => {
        setDisabled(true)
        API.graphql(graphqlOperation(AddBundleCreditAdmin, {
            UserID: user.UserID,
            Credit: parseInt(inputRef.current.value)
        }))
        .then(result => {
            setSub(pass => {
                let newSub = pass
                newSub.Point.BundlePoint = (pass?.Point?.BundlePoint || 0) + parseInt(inputRef.current.value)
                return newSub
            })
            setDisabled(false)
            setOpen(false)
        })
        .catch(err => console.log(err))
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle className={useStyle().dialogTitle}>
                <Box pt={5} px={5} fontWeight='fontWeightBold'>
                    Add Bundle Credits
                </Box>
                <IconButton onClick={() => setOpen(false)} className={useStyle().closeButton}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box py={5} textAlign='center'>
                    <TextField
                        variant='outlined'
                        label='Enter credits'
                        type='number'
                        inputProps={
                            {min: 0, step: 1}
                        }
                        inputRef={inputRef}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container direction='row' justify='center' spacing={5}>
                    <Grid item>
                        <Box pb={5}>
                            <ButtonTemplate
                                variant='outlined'
                                color='primary'
                                size='small'
                                changetextcolor='#fff'
                                borderradius='5px'
                                onClick={() => setOpen(false)}
                            >Cancel</ButtonTemplate>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box pb={5}>
                            <ButtonTemplate
                                variant='contained'
                                color='primary'
                                size='small'
                                changetextcolor='#fff'
                                borderradius='5px'
                                onClick={handleAdd}
                                disabled={disabled}
                            >Add</ButtonTemplate>
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}