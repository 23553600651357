import React from 'react';
import { Box, Typography, Checkbox, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    pointerStyle: {
        cursor: 'pointer',
        lineHeight: '0.6em'
    },

    textUnderline: {
        textDecoration: 'underline',
        color: theme.palette.primary.main
    }
}))

export const PrivateAccount = ({ account, handleSetAccount }) => {
    const classes = useStyle();
    return (
        <Box py={5} display='inline-block'>
            <Typography variant='body1' className={classes.pointerStyle} paragraph={true} align='center'>
                <Checkbox checked={account.Private === 0 ? false : true} color='primary' onClick={() => handleSetAccount("Private", account.Private === 0 ? 1 : 0)} />
                <span onClick={() => handleSetAccount("Private", account.Private === 0 ? 1 : 0)}>Private account</span>
            </Typography>
        </Box>
    )
}